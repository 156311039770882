import { Config } from '../../interfaces/config.interface';
import Logo from './logo-horizontall-sin-fondo.png';
import LogoLogin from './logo-vertical-sin-fondo.png';

const config: Config = {
  name: 'Fundación Noemi Frida Kraut',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: '220px',
  alternative_logo: {
    title: 'Fundación Noemi Frida Kraut',
    img1: LogoLogin,
  },
};

export default config;
